<template>
  <div class="card-columns">
    <appCheckDetails :propsCheckDetails="propsData2"></appCheckDetails>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Personal details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData1">
          <dd class="col-sm-5 text-black-60 fw-600">Given name</dd>
          <dd class="col-sm-7">
            {{ propsData1.first_name }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
          <dd class="col-sm-7">{{ propsData1.middle_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Family name</dd>
          <dd class="col-sm-7">{{ propsData1.last_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Date of birth</dd>
          <dd class="col-sm-7">{{ propsData1.date_of_birth }}</dd>
        </dl>
      </div>
    </div>

    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Employment History</h5>
      </div>
      <div class="card-body">
        <div class="accordion" role="tablist">
          <div
            v-for="(item, index) in propsData2.check_employment_history_trans"
            :key="index"
          >
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  href="#"
                  v-b-toggle="'accordion-' + index"
                  variant="secondary"
                  class="text-left"
                  >{{ item.name_of_organisation }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="item.note"></b-card-text>
                  <div class="mb-1" :key="index">
                    <dl class="row">
                      <dd class="col-sm-5 text-black-60 fw-600">
                        <div>
                          <p class="mb-0">Name of organisation</p>
                        </div>
                      </dd>
                      <dd class="col-sm-7">
                        {{ item.name_of_organisation }}
                      </dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        <div>
                          <p class="mb-0">Employer Payroll Phone Number</p>
                        </div>
                      </dd>
                      <dd class="col-sm-7">{{ item.payroll_phone_number }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Employer address
                      </dd>
                      <dd class="col-sm-7">{{ item.employer_address }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">Country</dd>
                      <dd class="col-sm-7">{{ item.country_code }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Employer HR/Payroll Email
                      </dd>
                      <dd class="col-sm-7">
                        {{ item.employer_payroll_email }}
                      </dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Manager Name
                      </dd>
                      <dd class="col-sm-7">{{ item.manager_name }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Position Held From date
                      </dd>
                      <dd class="col-sm-7">
                        {{ item.date_from_position_held }}
                      </dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Position Held To date
                      </dd>
                      <dd class="col-sm-7">{{ item.date_to_position_held }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">Role Title</dd>
                      <dd class="col-sm-7">{{ item.role_title }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Nature of employment
                      </dd>
                      <dd class="col-sm-7">{{ item.nature_of_employment }}</dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Is this your current employer
                      </dd>
                      <dd class="col-sm-7">{{ item.is_current_employer }}</dd>
                      <dd
                        class="col-sm-5 text-black-60 fw-600"
                        v-if="item.is_current_employer == 'No'"
                      >
                        Please provide a reason for leaving
                      </dd>
                      <dd
                        class="col-sm-7"
                        v-if="item.is_current_employer == 'No'"
                      >
                        {{ item.reason_for_leaving }}
                      </dd>

                      <dd class="col-sm-5 text-black-60 fw-600">
                        Can we contact this employer
                      </dd>
                      <dd class="col-sm-7">{{ item.can_contact_employer }}</dd>

                      <dd
                        class="col-sm-5 text-black-60 fw-600"
                        v-if="item.can_contact_employer == 'No'"
                      >
                        Proof of employment
                      </dd>
                      <dd
                        class="col-sm-7"
                        v-if="item.can_contact_employer == 'No'"
                      >
                        <wdImageAvatarPreview
                          :title="'Proof of employment'"
                          :coverImgUrl="item.doc_urls"
                          :fontAwClass="'fas fa-file-alt'"
                        ></wdImageAvatarPreview>
                      </dd>
                    </dl>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appCheckDetails from "../project/checkDetails.vue";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
export default {
  name: "checkEmploymentHistoryDetail",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    appCheckDetails,
    wdImageAvatarPreview,
  },
};
</script>