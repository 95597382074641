<template>
  <div class="p-3">
    <appCheckEmploymentHistoryView
      :propsData1="getUserFromId"
      :propsData2="getEmploymentHistory"
    ></appCheckEmploymentHistoryView>
  </div>
</template>
<script>
import appCheckEmploymentHistoryView from "../../components/checkEmploymentHistory/checkEmploymentHistoryView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "CheckProfessionalMembershipView",
  components: {
    appCheckEmploymentHistoryView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getEmploymentHistory", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchEmploymentHistory", "fetchUserFromId"]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchEmploymentHistory(this.projectId);
  },
};
</script>

